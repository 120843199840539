/* eslint-disable max-len */
import React, { FormEvent, useContext, useState, } from 'react'
import { makeStyles } from '@mui/styles'
import { CircularProgress, FormHelperText, InputAdornment, TextField, Theme, Typography, } from '@mui/material'
import AccountBox from '@mui/icons-material/Person'
import Apartment from '@mui/icons-material/Apartment'
import NumbersIcon from '@mui/icons-material/Numbers'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import toast from 'react-hot-toast'
import CustomButton from '../../components/CustomButton'
import isValidIsraeliID from '../../functions/isValidIsraeliId'
import getQueryStringValue from '../../utils/getQueryStringValue'
import { loanContext } from './LoanJourney'

const themeColor = getQueryStringValue('themeColor')

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  title: {
    // paddingBottom: spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: spacing(2),
  },
  errorMessageContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'end'
  },
  'customFileUpload': {
    border: '1px solid #ccc',
    display: 'inline - block',
    padding: '6px 12px',
    cursor: 'pointer',
  }
}), { name: 'PersonalDetails' })

const PersonalDetails = (): JSX.Element => {
  const classes = useStyles()

  const [companyName, setCompanyName] = useState('')
  const [companyHP, setCompanyHP] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const {
    setStage,
    errorMessage,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    nationalId,
  } = useContext(loanContext)

  const handleClickContinue = (e: FormEvent): void => {
    e.preventDefault()

    // eslint-disable-next-line no-constant-condition
    if (isValidIsraeliID(nationalId) || true) {
      // setStage((prev) => prev + 1)
      setIsLoading(true)

      setTimeout(() => {
        setIsLoading(false)

        setStage((prev) => prev + 1)
      }, 2000)
    } else {
      toast.error('מספר ת.ז לא תקין')
    }
  }

  if (isLoading) {
    if (isLoading) {
      return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Typography>
          מתבצע אימות למספר הטלפון
        </Typography>
        <Typography>
          אנא המתן
        </Typography>
        <CircularProgress size={50} sx={{ margin: 3, color: themeColor }} />
      </div>
    }
  }

  return (
    <div className={`${classes.root} rtl`} >
      <Typography variant='h5' className={classes.title}>איש קשר</Typography>
      <form className={classes.form} onSubmit={handleClickContinue}>
        <TextField
          sx={{ paddingLeft: 1 }}
          value={companyName}
          onChange={(({ target }) => setCompanyName(target.value))}
          label='שם החברה'
          id="company-name"
          margin="dense"
          type='text'
          InputLabelProps={{
            sx: {
              textAlign: 'center'
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Apartment />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ paddingLeft: 1 }}
          value={companyHP}
          onChange={(({ target }) => setCompanyHP(target.value))}
          label='ח.פ/ע.מ'
          id="HP-name"
          margin="dense"
          type='text'
          InputLabelProps={{
            sx: {
              textAlign: 'center'
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <NumbersIcon />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: 'flex' }}>
          <TextField
            sx={{ paddingLeft: 1 }}
            value={firstName}
            onChange={(({ target }) => setFirstName(target.value))}
            label='שם פרטי'
            id="first-name"
            margin="dense"
            type='text'
            InputLabelProps={{
              sx: {
                textAlign: 'center'
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountBox />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={lastName}
            onChange={(({ target }) => setLastName(target.value))}
            label='שם משפחה'
            id="last-name"
            margin="dense"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountBox />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TextField value={email} onChange={(({ target }) => setEmail(target.value))}
          label='דוא״ל'
          id="email"
          margin="dense"
          // type='email'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField required value={phone} onChange={(({ target }) => setPhone(target.value))}
          label='טלפון נייד'
          id="phone"
          margin="dense"
          type='tel'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.errorMessageContainer}>
          <FormHelperText error sx={{ height: 5 }}>
            {errorMessage}
          </FormHelperText>
        </div>
        {/* </FormControl> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomButton /* onClick={handleClickContinue} */ type='submit'>
            המשך
          </CustomButton>
        </div>
      </form>

    </div>
  )
}

export default PersonalDetails
