/* eslint-disable max-len */
import React, { createContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Dialog, IconButton, MobileStepper, Slide, SlideProps, Theme, Toolbar } from '@mui/material'
import { ArrowBack, Close } from '@mui/icons-material'
// import { getPlans } from '../../data/plans'
import useWindowSize from '../../hooks/useWindowSize'
// import logo from '../../assets/icons/hr2.png'
import getQueryStringValue from '../../utils/getQueryStringValue'
import { Bank, LoanContextType, Plan } from '../../@types'
import PersonalDetails from './PersonalDetails'
import LoanDetails from './LoanDetails'
import BeforeExitDialog from './BeforeExitDialog'
import OTP from './OTP'

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 660,
    width: 400,
    [breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      alignSelf: 'center',
    },
  },

  appBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    zIndex: 3,
    border: 0,
  },
  logoContainer: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    userSelect: 'none',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 24,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  icon: {
    width: 25,
    '& svg': {
      width: 25,
      height: 25,
    },
  }
}), { name: 'LoanJourney' })

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (<Slide direction="up" ref={ref} {...props} />))

export const loanContext = createContext<LoanContextType>({} as LoanContextType)

const LoanJourney = (): JSX.Element => {
  const classes = useStyles()
  const isAfterOtp = window.location.search.indexOf('otp=true')
  const [open, setOpen] = useState<boolean>(true)
  const [isCloseDialog, setIsCloseDialog] = useState(false)
  const [stage, setStage] = useState<number>(isAfterOtp !== -1 ? 2 : 0)
  const [plans, setPlans] = useState<Array<Plan> | []>([])
  const [loanPlan, setLoanPlan] = useState<Plan | undefined>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [logoUrl] = useState(() => {
    const customerName = getQueryStringValue('customerName')

    // return 'https://michman-loans.co.il/wp-content/uploads/2020/01/scrolling-logo.png'
    return `https://open-finance-assets.s3.eu-west-1.amazonaws.com/customers-logos/${customerName}.png`
  })

  const [phone, setPhone] = useState<string>(window.localStorage.getItem('phone_number') ?? '')
  const [otp, setOtp] = useState<string>('')

  const [loanType, setLoanType] = useState<string>('')
  const [loanAmount, setLoanAmount] = useState<any>('')
  const [loanPeriod, setLoanPeriod] = useState<any>('')
  const [chosenBank, setChosenBank] = useState<Bank>({} as Bank)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [birthday, setBirthday] = useState<any>(null)
  const [email, setEmail] = useState<string>('')
  const [nationalId, setNationalId] = useState<string>('')
  const [isConsentRead, setIsConsentRead] = useState<boolean>(false)
  const [isConfirmTerms, setIsConfirmTerms] = useState<boolean>(false)
  const [creditCard, setCreditCard] = useState('')
  const [creditCardValidity, setCreditCardValidity] = useState<any>(null)
  const [creditCardBackNumbers, setCreditCardBackNumbers] = useState('')
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [branchNumber, setBranchNumber] = useState<string>('')
  const [bankNumber, setBankNumber] = useState<string>('')
  const [isLoanConfirmed, setIsLoanConfirmed] = useState(false)

  const contextProviderValue = {
    setOpen,
    stage,
    setStage,
    loanPlan,
    setLoanPlan,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    phone,
    setPhone,
    otp,
    setOtp,

    isLoanConfirmed,
    setIsLoanConfirmed,
    loanType,
    setLoanType,
    loanAmount,
    setLoanAmount,
    loanPeriod,
    setLoanPeriod,
    chosenBank,
    setChosenBank,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    birthday,
    setBirthday,
    email,
    setEmail,
    nationalId,
    setNationalId,
    isConsentRead,
    setIsConsentRead,
    isConfirmTerms,
    setIsConfirmTerms,
    plans,
    setPlans,
    creditCard,
    setCreditCard,
    creditCardValidity,
    setCreditCardValidity,
    creditCardBackNumbers,
    setCreditCardBackNumbers,
    accountNumber,
    setAccountNumber,
    branchNumber,
    setBranchNumber,
    bankNumber,
    setBankNumber,
  }

  const handleCloseDialog = (): void => {
    setIsCloseDialog(true)
  }

  const backClicked = (): void => {
    if (stage === 0) return handleCloseDialog()

    if (stage - 1 === 0) {
      setIsLoanConfirmed(false)
    }

    setStage(Math.floor(stage - 1))
    setErrorMessage('')
    setIsLoading(false)
  }

  const windowSize = useWindowSize()

  return (
    <loanContext.Provider value={contextProviderValue}>
      <div className={classes.root}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          fullScreen={Number(windowSize.width) < 600 ? true : false}
        >
          <div>
            <Toolbar className={classes.appBar}>
              <div>
                {stage <= 7 && stage > 0 && !isCloseDialog &&
                  <IconButton
                    className={classes.icon}
                    edge='start'
                    color='inherit'
                    onClick={backClicked}
                    aria-label="back"
                  >
                    <ArrowBack />
                  </IconButton>
                }
              </div>
              <div className={classes.logoContainer}>
                <img src={logoUrl} alt='logo' width='150' height='90' />
              </div>
              <IconButton
                className={classes.icon}
                edge='start'
                color='inherit'
                aria-label="close"
                onClick={handleCloseDialog}
              >
                {!isCloseDialog && <Close />}
              </IconButton>
            </Toolbar>
            {stage >= 0 && !isCloseDialog &&
              <MobileStepper
                variant='progress'
                steps={3}
                position='static'
                activeStep={stage}
                sx={{ display: 'flex', flex: 1, justifyContent: 'center', background: 'none' }}
                nextButton={null}
                backButton={null}
              />
            }
          </div>
          {isCloseDialog && (
            <div className={classes.dialogContent}>
              <BeforeExitDialog closeDialog={() => setOpen(false)} cancelCloseDialog={() => setIsCloseDialog(false)} />
            </div>)}
          {!isCloseDialog && (
            <div className={classes.dialogContent}>
              {stage === 0 && <LoanDetails />}
              {stage === 1 && <PersonalDetails />}
              {stage === 2 && <OTP />}

              {/* {stage === 50 && <LoanDetailsOLD />}
              {stage === 3 && <ConsentForm />}
              {stage === 4 && <ChooseBank />}
              {stage === 5 && <BankLogin bank={chosenBank} />}
              {stage === 6 && <ChoosePlan />}
              {stage === 7 && <JourneyFinish />} */}
            </div>)}
        </Dialog>
      </div>
    </loanContext.Provider>
  )
}

export default LoanJourney
