import { webAuth } from '../utils'
import { SendOTP, VerifyOTP } from '../@types'

const sendOtpViaSms: SendOTP = (phoneNumber) => new Promise((resolve, reject) => {
  webAuth.passwordlessStart({
    connection: 'sms',
    send: 'code',
    phoneNumber
  }, (err, res) => {
    if (err) {
      return reject(err)
    }

    return resolve(res)
  })
})

const verifyOtpFromSms: VerifyOTP = (phoneNumber, otp) => new Promise((resolve, reject) => {
  webAuth.passwordlessLogin({
    connection: 'sms',
    phoneNumber,
    verificationCode: otp,
  }, (err, res) => {
    if (err) {
      return reject(err)
    }

    return resolve(res)
  })
})

export {
  sendOtpViaSms,
  verifyOtpFromSms,
}
