import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { CircularProgress, FormHelperText, Theme, Typography } from '@mui/material'
import OtpInput from 'react-otp-input'
import otpImage from '../../assets/icons/otp3.jpeg'
import CustomButton from '../../components/CustomButton'
import { sendOtpViaSms, verifyOtpFromSms } from '../../functions/auth0'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: spacing(3),
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  title: {
    padding: spacing(2),
    letterSpacing: 0.2,
    fontFamily: 'monospace'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing(3),
  },
  otp: {
    height: 50,
    width: '45px !important',
    background: 'rgb(203 203 203 / 30%);',
    borderRadius: 5,
    borderWidth: 0,
    color: 'black',
    fontSize: 20,
    fontWeight: 'lighter',
  },
  resendCodeMessage: {
    fontSize: 12,
  },
  resendCodeContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  resendCodeBtn: {
    paddingTop: spacing(),
    paddingButton: spacing(3),
    '&:hover': {
      color: 'blue'
    }
  },
  backBtn: {
    backgroundColor: 'grey',
  },
}), { name: 'OTP' })

const otpLength = 6

const OTP = (): JSX.Element => {
  const classes = useStyles()

  const {
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    otp,
    setOtp,
    phone,
  } = useContext(loanContext)

  const validateForm = (): boolean => {
    let msg = ''
    let valid = true

    if (!otp || otp.length !== otpLength) {
      msg = 'אנא הכנס את הקוד'
      valid = false
    }

    setErrorMessage(msg)

    return valid
  }

  const handleClickContinue = async (): Promise<void> => {
    if (validateForm()) {
      try {
        setIsLoading(true)
        window.localStorage.setItem('phone_number', phone)
        await verifyOtpFromSms(phone, otp)
        // Example on returned response from above await function
        // { unknown } - Auth0 sends a redirect!
      } catch (error: any) { // eslint-disable-line
        window.localStorage.removeItem('phone_number')
        if (error.code === 'access_denied') {
          setErrorMessage('קוד שוגי, אנא נסה שנית')
        } else if (error.code === 'too_many_attempts') {
          console.error(error)
        } else {
          console.error(error)
        }
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (otp.length === otpLength && validateForm()) {
      handleClickContinue()
    }
  }, [otp]) // eslint-disable-line

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img src={otpImage} alt='img' width='200' height='200' />
      </div>

      <div>
        <Typography fontFamily='sans-serif' className={classes.title}>
          הכנס את הקוד שנשלח אליך בהודעה
        </Typography>
        <OtpInput
          value={otp}
          onChange={(otp: string) => setOtp(otp)}
          numInputs={otpLength}
          separator={<span style={{ padding: 6 }}></span>}
          containerStyle={classes.otpContainer}
          inputStyle={classes.otp}
          shouldAutoFocus={true}
          isInputNum={true}
        />
        <div style={{ height: 20 }}>
          {isLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <FormHelperText error sx={{ display: 'flex', justifyContent: 'center' }}>
              {errorMessage}
            </FormHelperText>
          )}
        </div>
      </div>

      <div>
        <Typography sx={{ fontSize: 14, color: 'gray' }}>
          לא קבלת את הקוד?
        </Typography>
        <div className={classes.resendCodeContainer}>
          <Typography
            onClick={async () => await sendOtpViaSms(phone)} className={classes.resendCodeBtn}
            sx={{ fontSize: 14, textDecoration: 'underline' }}
          >
            לחץ כאן לשליחה מחדש
          </Typography>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomButton onClick={handleClickContinue}>
          המשך
        </CustomButton>
      </div>
    </div>
  )
}

export default OTP

