/* eslint-disable max-len */
import React, { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Theme, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CustomButton from '../../components/CustomButton'
import getQueryStringValue from '../../utils/getQueryStringValue'
import CustomSlider from '../../components/generic/CustomSlider'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  title: {
    // paddingBottom: spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: spacing(2),
    placeContent: 'space-between',
  },
  errorMessageContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'end'
  },
  'customFileUpload': {
    border: '1px solid #ccc',
    display: 'inline - block',
    padding: '6px 12px',
    cursor: 'pointer',
  }
}), { name: 'EmploymentStatusDetails' })

const themeColor = getQueryStringValue('themeColor')

const loanPurposeTypes = [
  'ניכיון שיקים',
  'הלוואה כללית',
  'הלוואה כנגד שעבוד נדל״ן/ציוד',
]

const creditCardPurposeTypes = [
  'השקעה',
  'הון חוזר',
  'מיחזור אשראי קיים',
  'אחר',
]

const companyBranches = [
  'בניה',
  'מזון',
  'תעשייה',
  'תיירות',
  'בריאות'
]

const EmploymentStatusDetails = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
  } = useContext(loanContext)

  const [loanPurpose, setLoanPurpose] = useState('')
  const [creditCardPurpose, setCreditCardPurpose] = useState('')
  const [companyBranch, setCompanyBranch] = useState('')
  const [creditAmount, setCreditAmount] = useState('')
  const [establishBusinessDate, setEstablishBusinessDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [averageMonthlyTurnover, setAverageMonthlyTurnover] = useState('')

  const getYearsValue = (value: any): string => {
    if (value === 12) {
      return 'שנה'
    }
    else if (value === 24) {
      return 'שנתיים'
    }
    else {
      return `${value / 12} שנים`
    }
  }

  const handleClickContinue = (): void => {
    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)

      setStage((prev) => prev + 1)
    }, 3000)
  }

  if (isLoading) {
    if (isLoading) {
      return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Typography>
          מתבצעת בדיקת תנאי סף
        </Typography>
        <Typography>
          אנא המתן
        </Typography>
        <CircularProgress size={50} sx={{ margin: 3, color: themeColor }} />
      </div>
    }
  }

  return (
    <div className={`${classes.root} rtl`} >
      <Typography variant='h6' className={classes.title} fontWeight='bold'>
        שאלון ראשוני
      </Typography>
      <form className={classes.form} onSubmit={handleClickContinue}>
        <FormControl fullWidth margin='dense'>
          <>
            <InputLabel id='loan-purpose-select-label' >מה אני צריך?</InputLabel>
            <Select
              labelId="loan-purpose-select-label"
              value={loanPurpose}
              label='מה אני צריך?'
              placeholder='מה אני צריך?'
              onChange={({ target }) => setLoanPurpose(target.value)}
              sx={{ textAlign: 'right', marginBottom: 1 }}
            >
              {
                loanPurposeTypes.map((type, index) => <MenuItem sx={{ placeContent: 'end' }} key={index} value={type}>{type}</MenuItem>)
              }
            </Select>
          </>
        </FormControl>
        <FormControl fullWidth >
          <>
            <InputLabel id='credit-purpose-select-label' >מטרת האשראי</InputLabel>
            <Select
              labelId="credit-purpose-select-label"
              value={creditCardPurpose}
              label='מטרת האשראי'
              placeholder='מטרת האשראי'
              onChange={({ target }) => setCreditCardPurpose(target.value)}
              sx={{ textAlign: 'right', marginBottom: 1 }}
            >
              {
                creditCardPurposeTypes.map((type, index) => <MenuItem sx={{ placeContent: 'end' }} key={index} value={type}>{type}</MenuItem>)
              }
            </Select>
          </>
        </FormControl>
        <TextField
          fullWidth
          value={creditAmount}
          onChange={({ target }) => setCreditAmount(target.value)}
          label='סכום אשראי'
          margin='dense'
        />
        <div style={{ display: 'flex', marginTop: 18 }}>
          <Typography textAlign='right' sx={{ padding: 2 }}>
            תקופה
          </Typography>
          <CustomSlider
            defaultValue={12}
            getAriaValueText={getYearsValue}
            valueLabelFormat={getYearsValue}
            valueLabelDisplay="on"
            step={12}
            min={12}
            max={120}
            aria-labelledby="non-linear-slider"
            sx={{ width: 280, color: themeColor, alignSelf: 'center' }}
          />
        </div>
        <div>
          <Divider>
            פרטי החברה
          </Divider>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <>
              <InputLabel id='company-branch-select-label' >תחום פעילות החברה</InputLabel>
              <Select
                labelId="company-branch-select-label"
                value={companyBranch}
                label='תחום פעילות החברה'
                placeholder='תחום פעילות החברה'
                onChange={({ target }) => setCompanyBranch(target.value)}
                sx={{ textAlign: 'right', marginBottom: 1 }}
              >
                {
                  companyBranches.map((type, index) => <MenuItem sx={{ placeContent: 'end' }} key={index} value={type}>{type}</MenuItem>)
                }
              </Select>
            </>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="תאריך הקמת העסק"
              inputFormat="MM/yyyy"
              value={establishBusinessDate}
              onChange={(newValue) => {
                setEstablishBusinessDate(newValue)
              }}
              renderInput={(params) => <TextField {...params} sx={{ marginBottom: 1 }} margin='dense' fullWidth />}
            />
          </LocalizationProvider>
          <TextField
            value={averageMonthlyTurnover}
            onChange={({ target }) => setAverageMonthlyTurnover(target.value)}
            label='מחזור חודשי'
            margin='dense'
            fullWidth
            sx={{ marginBottom: 2 }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomButton onClick={handleClickContinue} type='submit'>
            המשך
          </CustomButton>
        </div>
      </form>

    </div >
  )
}

export default EmploymentStatusDetails
